$rotate-md: -12deg;
$mock-xl-width: 829px;
$device-margin: 50px;

.lightweight-template .learn-more {
  margin-bottom: 18rem;
}

.isometric-mockups {
  pointer-events: none;
  position: relative;
  display: flex-start;
  align-items:left;
  left:-20%;
 

 .desktop4 {
  margin-top: 15px; 
  width:250px;
  transform:scale(1.5)  translate(50px, -35px);
}

  > div {
    margin: 0 50px;
  }
  


  @include media-breakpoint-up(sm) {
  
 
  }

  @include media-breakpoint-up(md) {
    margin-left: 0;
    pointer-events: none;
    position: relative;
    display: flex-start;
    align-items:center;
    left:-2%;
   
    .desktop4 {
      width:500px;
      transform:scale(1.5)  translate(50px, -35px);
    }
   

  }


}

