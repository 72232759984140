$navbar-min-height: 90px;
$navbar-logo-max-height: 60px;
$navbar-font-size: 0.9rem;
$navbar-background-color: $white; //$living-coral;
$navbar-text-color: $living-coral;
$navbar-text-color-hover: $living-coral-dark-2x;
$navbar-text-color-dark: $beluga-dark-4x;
$navbar-text-color-dark-hover: $beluga-dark-5x;

$navbar-fixed-top-background-color: transparent;
$navbar-fixed-text-color: $living-coral-light-5x;
$navbar-fixed-text-color-hover: $living-coral-light-4x;

$navbar-sticky-background-color : $white;
$navbar-sticky-text-color: $living-coral;
$navbar-sticky-text-color-hover: $living-coral-dark-3x;

$navbar-side-width: 250px;
$navbar-side-background-color: $white; //$living-coral;
$navbar-side-text-color: $living-coral;
$navbar-side-text-color-hover: $living-coral-dark-2x;
$navbar-side-border-color: $border-color;
